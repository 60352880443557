html {
  background: $white;
  color: $text-color;
  font-family: $font-family-base;
  font-size: rem-calc(16); /* [1] */
  line-height: 1.5; /* [1] */
  min-height: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $body-bg;

  @include media-width(640px) {
    font-size: $font-size-small;
  }
}
