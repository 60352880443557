@mixin absolute_center_vertical() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin absolute_center_both() {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: auto;
  transform: translateY(-50%);
}

@mixin absolute_cover() {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
}

@mixin zindex($index) {
  z-index: $index;
}

@mixin list_caret($color:$theme-color) {
  li {
    &:before {
      margin-right: 8px;
      content: '\f0da';
      font-size: 15px;
      font-family: 'FontAwesome'; /* stylelint-disable-line font-family-name-quotes */
      color: $color
    }
  }
}

@mixin image_resize() {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

@mixin loader($width: 200, $height: 200, $display: block, $margin: auto) {
  display: $display;
  width: $width * 1px;
  height: $height * 1px;
  margin: $margin;
  background-image: url('../assets/images/loader.svg');
  background-size: 100% 100%;
}

@mixin media-width($width) {
  @media all and (max-width: $width) {
    @content;
  }
}
