.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left:  0;
  padding-right: 0;
  @include clearfix;

  @media (min-width: $screen-sm-min) {
    max-width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    max-width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    max-width: $container-lg;
  }
}

.container-padlr {
  padding-left:  $padding-large-horizontal;
  padding-right: $padding-large-horizontal;

  @include media-width(768px) {
    padding-left:  $padding-small-horizontal;
    padding-right: $padding-small-horizontal;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left:  0;
  padding-right: 0;
  @include clearfix;
}

.col-xs-20,
.col-sm-20,
.col-md-20,
.col-xs-17, // one seventh
.col-sm-17,
.col-md-17 {
  position: relative;
  float: left;
  box-sizing: border-box;
  min-height: 1px;
  padding-left: 0;
  padding-right: 0;
  width: 100%;

  @media (min-width: $screen-sm-min) {
    width: 20%;
  }
  @media (min-width: $screen-md-min) {
    width: 20%;
  }
  @media (min-width: $screen-lg-min) {
    width: 20%;
  }
}

.col-xs-20,
.col-sm-20,
.col-md-20 {
  @media (min-width: $screen-sm-min) {
    width: 20%;
  }
  @media (min-width: $screen-md-min) {
    width: 20%;
  }
  @media (min-width: $screen-lg-min) {
    width: 20%;
  }
}

.col-xs-17,
.col-sm-17,
.col-md-17 {
  @media (min-width: $screen-sm-min) {
    width: 14.28571%;
  }
  @media (min-width: $screen-md-min) {
    width: 14.28571%;
  }
  @media (min-width: $screen-lg-min) {
    width: 14.28571%;
  }
}
