@mixin button-variants($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: lighten($background, 10%);
    border-color: lighten($border, 25%);
  }

  &:hover {
    color: $color;
    background-color: lighten($background, 10%);
    border-color: lighten($border, 12%);
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: lighten($background, 10%);
    border-color: lighten($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: lighten($background, 17%);
      border-color: lighten($border, 25%);
    }
  }
  
  &:active,
  &.active,
  .open > &.dropdown-toggle { /* stylelint-disable-line no-duplicate-selectors */
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}
