h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;

  small,
  .small {
    font-weight: normal;
    line-height: 1;
    color: $headings-small-color;
  }
}

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: ($line-height-computed / 2);
  margin-bottom: ($line-height-computed / 2);

  small,
  .small {
    font-size: 65%;
  }
}

h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: ($line-height-computed / 2);
  margin-bottom: ($line-height-computed / 2);

  small,
  .small {
    font-size: 75%;
  }
}

h1, .h1 {font-size: $font-size-h1; }
h2, .h2 {font-size: $font-size-h2; }
h3, .h3 {font-size: $font-size-h3; }
h4, .h4 {font-size: $font-size-h4; }
h5, .h5 {font-size: $font-size-h5; }
h6, .h6 {font-size: $font-size-h6; }

@include media-width(1366px) {
  h1, .h1 {font-size: $font-size-h2; }
}

@include media-width(1152px) {
  h2, .h2 {font-size: $font-size-h3; }
  h3, .h3 {font-size: $font-size-h4; }
  h4, .h4 {font-size: $font-size-h5; }
  h5, .h5 {font-size: $font-size-h6; }
}

@include media-width(992px) {
  h1, .h1 {font-size: $font-size-h3; }
  h2, .h2 {font-size: $font-size-h4; }
  h3, .h3 {font-size: $font-size-h5; }
  h4, .h4 {font-size: $font-size-h6; }
  h5, .h5 {font-size: $font-size-small; }
}

p {
  margin: 0 0 ($line-height-computed / 2);
  line-height: $line-height-computed + 2;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: ($line-height-computed / 2);

  ul,
  ol {
    margin-bottom: 0;
  }
}

blockquote {
  padding: ($line-height-computed / 2) $line-height-computed;
  margin: 0 0 $line-height-computed;
  font-size: $blockquote-font-size;
  border-left: 5px solid $blockquote-border-color;
  line-height: $line-height-md;

  p,
  ul,
  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }

  // Note: Deprecated small and .small as of v3.1.0
  // Context: https://github.com/twbs/bootstrap/issues/11660

  footer,
  small,
  .small {
    display: block;
    font-size: 80%; // back to default font-size
    line-height: $line-height-base;
    color: $blockquote-small-color;

    &:before {
      content: '\2014 \00A0'; // em dash, nbsp
    }
  }
}

strong {
  font-weight: 900;
}
