.pagination-container {
  position: relative;
  margin-top: 50px;
  text-align: center;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 0;
  border-radius: $border-radius-base;

  > li {
    display: inline; // Remove list-style and block-level defaults
    > a,
    > span {
      position: relative;
      float: left; // Collapse white-space
      width: 40px;
      height: 40px;
      padding: 0;
      padding-top: 9px;
      line-height: $line-height-base;
      text-decoration: none;
      color: $pagination-color;
      background-color: $pagination-bg;
      font-weight: $font-bold;
      border: 1px solid $pagination-border;
      margin-left: 0;
      margin: 0 3px;
      transition: all 0.3s ease-in-out;

      @include media-width(992px) {
        width: 36px;
        height: 36px;
        padding-top: 6px;
      }
    }

    &:first-child,
    &:last-child {
      position: absolute;
      top: 0;

      > a,
      > span {
        padding: 0;
        padding-top: 7px;
        color: $white;
        background-color: $gray-light;
        font-size: $font-size-large;

        @include media-width(992px) {
          font-size: $font-size-medium-large;
          padding-top: 5px;
        }
      }
    }

    &:first-child {
      left: 0;

      > a,
      > span {
        margin-left: 0;
        @include border-left-radius(0);
      }
    }
    &:last-child {
      right: 30px;

      > a,
      > span {
        @include border-right-radius(0);
      }

      @include media-width(768px) {
        right: 0;
      }
    }

  }

  > li > a, /* stylelint-disable-line no-duplicate-selectors */
  > li > span {
    &:hover,
    &:focus {
      z-index: 2;
      color: $pagination-hover-color;
      background-color: $pagination-hover-bg;
      border-color: $pagination-hover-border;
    }
  }

  > .active > a,
  > .active > span,
  > li > span.current { /* stylelint-disable-line selector-no-qualifying-type */
    &,
    &:hover,
    &:focus {
      z-index: 3;
      color: $pagination-active-color;
      background-color: $pagination-active-bg;
      border-color: $pagination-active-border;
      cursor: default;
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      color: $pagination-disabled-color;
      background-color: $pagination-disabled-bg;
      border-color: $pagination-disabled-border;
      cursor: $cursor-disabled;
    }
  }
}

.posts-prev-next {
  padding: 15px 0;
  // border-top: 1px solid $gray-light;
  text-transform: uppercase;

  a {
    color: $gray-dark;
    font-weight: $font-bold;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
      color: $theme-color;
      text-decoration: none;
    }
  }
}
