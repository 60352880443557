button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

label {
  display: inline-block;
  max-width: 100%; // Force IE8 to wrap long content (see https://github.com/twbs/bootstrap/issues/13141)
  margin-bottom: 5px;
  font-weight: normal;
  font-size: $font-size-base;
}

.form-control {
  display: block;
  width: 100%;
  height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  padding: $padding-base-vertical $padding-base-horizontal;
  font-size: $font-size-small;
  line-height: $line-height-base;
  color: $input-color;
  background-color: $input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 2px solid $input-border;
  border-radius: $input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
  box-shadow: none;
  outline: none;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid $theme-color;
  }

  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    background-color: $input-bg-disabled;
    opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
  }

  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
  }
}

// Reset height for `textarea`s
textarea.form-control {
  height: 70px;
}

/* Chrome yellow fields fix */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
	box-shadow: 0 0 0px 1000px #fff inset;
	-webkit-box-shadow: 0 0 0px 1000px #fff inset;
}
