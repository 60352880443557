/*--------------------------------------
                  MISC
--------------------------------------*/
.highlight {
  color: $theme-color;
}

.body-inner {
  overflow: hidden;
}

/* Chrome yellow fields fix */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s !important;
	box-shadow: 0 0 0px 1000px #fff inset !important;
	-webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
}

.page-container img {
  @include image_resize();
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
}

.google-map img {
  max-width: none;
  max-height: none;
  width: auto;
  height: auto;
}

/*--------------------------------------
                GENERAL
--------------------------------------*/
.page-loader {
  @include absolute_cover();
  position: fixed;
  background-color: $white;
  z-index: 9999;

  img {
    @include absolute_center_both();
  }
}

.section-header {
  margin-bottom: 10px;

  &.margin-top {
    margin-top: 30px;
  }

  .section-header-tag {
    margin: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.inner-content {
  margin-bottom: 30px;
  padding: 35px;
  background-color: $white;
  border-radius: $border-radius-base;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.4);
  color: $gray-light;
  font-size: $font-size-small;
  
  @include media-width(768px) {
    padding: 15px;
    margin-bottom: 10px;
  }

  p {
    line-height: 20px;
  }

  .section-header-tag {
    text-transform: uppercase;
  }
}

.page-content {
  padding: 30px 0;

  @include media-width(768px) {
    padding: 20px 0;
  }
}

/*--------------------------------------
                HEADER
--------------------------------------*/
.navbar-menu,
.navbar-contact,
.quick-contact,
.social-links {
  display: inline-block;
  vertical-align: middle;
}

@include media-width(768px) {
  .container-header {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-header {
    .col-sm-2 {
      text-align: center;
    }

    .col-sm-10 {
      text-align: left;
    }
  }

  .navbar-menu {
    display: block;
  }

  .navbar-contact {
    display: block;
    float: none !important;
    text-align: center;
  }
}

.quick-contact {
  position: relative;
  padding: 5px 20px; 

  @include media-width(1152px) {
    padding: 15px 5px 5px 5px;
  }

  @include media-width(768px) {
    padding: 0 5px 5px 5px;
  }

  &:before {
    content: '';
    @include absolute_cover();
    background-color: $black;

    @include media-width(1152px) {
      display: none;
    }
  }

  p {
    position: relative;
    z-index: 3;
    margin: 0;
    font-weight: 900;

    &.qc-text {
      font-size: $font-size-xsmall;
      color: $white;

      @include media-width(1152px) {
        display: none;
      }
    }

    &.qc-phone {
      font-size: $font-size-large;
      color: $theme-color;

      a {
        text-decoration: none;
      }

      @include media-width(1152px) {
        span {
          display: none;
        }
      }
    }

    i.fa {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      font-size: 24px;
      
      @include media-width(768px) {
        position: relative;
        top: 1px;
        font-size: 20px;
        margin-right: 0;
      }
    }
  }
}

.social-links {
  margin-bottom: 0;
  padding: 0 0 0 15px;

  @include media-width(1152px) {
    padding: 13px 0 0 5px;
  }

  @include media-width(768px) {
    padding: 0 0 0 5px;
  }

  li {
    margin-left: 5px;

    a {
      font-size: $font-size-large;
      color: $gray-lighter;
      transition: $transition;

      &:hover {
        color: $theme-color;
      }

      @include media-width(768px) {
        line-height: 18px;
      }
    }
  }
}

/*--------------------------------------
              FLEXSLIDER
--------------------------------------*/
.flexslider {
  margin: 0 0 0;
  border: none;
  border-radius: 0;

  @include media-width(1152px) {
    max-height: 250px;
  }

  // @include media-width(768px) {
  //   height: 250px;
  // }

  @include media-width(480px) {
    max-height: 200px;
  }

  .slides {
    > li {
      position: relative;
      overflow: hidden;
      max-height: 360px;
      height: 360px;

      img {
        position: absolute;
        max-width: none;
        min-width: 100%;
        width: auto;
        height: auto;
        min-height: 100%;
        left: 50%;
        transform: translateX(-50%);
        max-height: none;
      }

      @include media-width(1152px) {
        max-height: 250px;
        height: 250px;
        //max-height: 250px;
        img {
        }
        // img {
        //   position: absolute;
        //   width: 1152px !important;
        //   max-width: none;
        //   //height: auto;
        //   left: 50%;
        //   transform: translateX(-50%);
        // }
      }

      // @include media-width(768px) {
      //   height: 250px;
      // }

      @include media-width(480px) {
        max-height: 200px;
      }
    }
  }

  .flex-caption {
    @include absolute_center_both();
    z-index: 9;
    opacity: 0;

    .flex-caption-inner {
      width: 55%;

      @include media-width(1152px) {
        width: 50%;
      }

      @include media-width(768px) {
        width: 45%;
      }

      @include media-width(640px) {
        width: 75%;
        margin: auto;
        padding: 10px;
        border-radius: $border-radius-base;
        background-color: rgba(255, 255, 255, 0.6);
        text-align: center;
      }

      @include media-width(480px) {
        width: 70%;
      }
    }

    .flex-caption-header {
      color: $white;
      font-size: 24px;
      font-weight: 900;

      @include media-width(640px) {
        margin-top: 0;
        color: $theme-green;
      }

      a {
        color: inherit;
        transition: all 0.3s ease-in-out;

        &:hover,
        &:focus {
          text-decoration: none;
          color: $white;
        }
      }

      strong {
        color: $black;
        font-size: 48px;
        font-weight: 900;

        @include media-width(1366px) {
          font-size: 36px;
        }

        @include media-width(1152px) {
          font-size: 28px;
        }

        @include media-width(992px) {
          font-size: 22px;
        }
        
        @include media-width(768px) {
          font-size: 20px;
        }

        @include media-width(480px) {
          font-size: 16px;
        }
      }

      @include media-width(992px) {
        font-size: 18px;
      }

      @include media-width(768px) {
        font-size: 16px;
      }

      @include media-width(480px) {
        font-size: 14px;
      }
    }

    .flex-caption-text {
      margin: 20px 0 25px;
      font-size: 24px;
      color: $white;
      line-height: 30px;

      @include media-width(1152px) {
        font-size: 20px;
        line-height: 24px;
      }

      @include media-width(992px) {
        font-size: 16px;
      }

      @include media-width(768px) {
        display: none;
      }
    }

    @include media-width(1280px) {
      padding-left: 60px;
      padding-right: 20px;
    }

    @include media-width(1110px) {
      padding-left: 50px;
      padding-right: 20px;
    }

    @include media-width(992px) {
      padding-left: 60px;
      padding-right: 20px;
    }

    @include media-width(640px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .flex-direction-nav {
    a {
      opacity: 1;
      width: auto;
      height: auto;
      margin: 0 0 0;
      @include absolute_center_vertical();

      &:before {
        font-family: '';
        content: '';
      }

      i.fa {
        margin-top: 10px;
        font-size: 24px;
        color: $theme-color;

        @include media-width(1280px) {
          margin-top: 8.5px;
          font-size: 22px;
        }

        @include media-width(992px) {
          margin-top: 9px;
          font-size: 18px;
        }
        
        @include media-width(768px) {
          margin-top: 7px;
          font-size: 16px;
        }
      }
    }

    .flex-prev,
    .flex-next {
      width: 48px;
      height: 48px;
      background-color: $black;
      text-align: center;
      border-radius: 50%;
      transition: $transition;

      @include media-width(1280px) {
        width: 40px;
        height: 40px;
      }

      @include media-width(992px) {
        width: 36px;
        height: 36px;
      }

      @include media-width(768px) {
        width: 32px;
        height: 32px;
      }
    }

    .flex-prev {
      left: 10px;
      padding-right: 3px;
    }
    
    .flex-next {
      right: 10px;
      padding-left: 3px;
    }

    @include media-width(640px) {
      .flex-prev {
        left: 5px;
      }
      
      .flex-next {
        right: 5px;
      }
    }
  }

  .flex-control-paging {
    display: block;
    bottom: 20px;
    z-index: 3;

    @include media-width(992px) {
      bottom: 10px;
    }

    @include media-width(480px) {
      bottom: 5px;
    }

    li {
      margin: 0 5px;

      a {
        width: 16px;
        height: 16px;
        background-color: $white;
        box-shadow: none;

        @include media-width(480px) {
          width: 12px;
          height: 12px;
        }

        &.flex-active {
          background-color: $black;
        }
      }
    }
  }

}

/*--------------------------------------
              OWL CAROUSEL
--------------------------------------*/
.owl-carousel {
  padding: 0;
}
.owl-stage {
  box-sizing: content-box;
  margin: 0 auto;
}
.owl-carousel .owl-item {
	-ms-transform:translateZ(0);
	transform:translateZ(0);

  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
}

.owl-nav {
	@include absolute_center_both();
  z-index: -1;

  .owl-prev,
  .owl-next {
    @include absolute_center_vertical();
    display:block;
    width: 40px;
    height: 40px;
    background-color: $gray-light;
    color: $white;
    font-size: $font-size-large;
    cursor:pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: lighten($gray-light, 10%);
    }

    .fa {
      @include absolute_center_both();
      text-align: center;
    }

    @include media-width(768px) {
      width: 35px;
      height: 35px;
      font-size: $font-size-medium-large;
    }
  }

  .owl-prev {
    left: -20px;
  }

  .owl-next {
    right: -20px;
  }
}

/*--------------------------------------
                ASSETS
--------------------------------------*/
.assets {
  
  &.assets-vertical {
    margin-top: 30px;

    @include media-width(768px) {
      margin-top: 10px;
    }
  }

  .asset {
    margin-bottom: 30px;
    padding: 0 15px;
    
    @include media-width(992px) {
      padding: 0 10px;
    }

    @include media-width(768px) {
      margin-bottom: 15px;
    }
  }

  .asset-inner {
    position: relative;
    overflow: hidden;
    padding: 25px 15px 25px 75px;
    background-color: $black;
    border-radius: $border-radius-base;

    @include media-width(1152px) {
      padding: 20px 10px 20px 60px;
    }

    @include media-width(992px) {
      max-width: 250px;
      margin: auto;
      padding: 20px 10px 20px 45px;
    }

    p {
      margin: 0;
      font-size: $font-size-base;
      color: $white;
      font-weight: 700;

      strong {
        font-size: 24px;
        color: $theme-color;

        @include media-width(1152px) {
          font-size: 20px;
        }

        @include media-width(992px) {
          font-size: 18px;
        }
      }
    }

    i.fa {
      @include absolute_center_vertical();
      left: 0;
      transform: translateY(-50%) translateX(-30%);
      position: absolute;
      font-size: 90px;
      color: $gray;

      @include media-width(1152px) {
        font-size: 80px;
        transform: translateY(-50%) translateX(-35%);
      }

      @include media-width(992px) {
        font-size: 65px;
        transform: translateY(-50%) translateX(-40%);
      }
    }

    i.fa-truck {
      transform: translateY(-50%) translateX(-30%) rotateY(180deg);

      @include media-width(1152px) {
        transform: translateY(-50%) translateX(-35%) rotateY(180deg);
      }

      @include media-width(992px) {
        transform: translateY(-50%) translateX(-40%) rotateY(180deg);
      }
    }
  }
}

/*--------------------------------------
                OFFER
--------------------------------------*/
.offer-item {
  padding: 15px;

  @include media-width(992px) {
    padding: 10px;
  }

  .offer-item-inner {
    overflow: hidden;
    max-width: 245px;
    width: 100%;
    background-color: $white;
    border-radius: $border-radius-base;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.4);
    transition: $transition;
    transform: perspective(1000px) translate3d(0, 0, 0);

    @include media-width(768px) {
      margin: auto;
    }

    &:hover {
      box-shadow: 0 1px 13px 1px rgba(0, 0, 0, 0.4);
      transform: perspective(1000px) translate3d(0, 0, 15px);
      -webkit-font-smoothing: subpixel-antialiased;
    }

    .offer-image {
      overflow: hidden;
      border-radius: $border-radius-base;
    }

    a {
      text-decoration: none;
    }

    h5 {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 20px;
      font-weight: 900;
      display: table;

      strong {
        display: table-cell;
        vertical-align: middle;
      }

      @include media-width(992px) {
        padding: 15px 10px;
      }

      span {
        @include absolute_center_vertical();
        right: 20px;
        display: block;
        width: 30px;
        height: 30px;
        background-color: $theme-color;
        border-radius: 50%;

        @include media-width(992px) {
          right: 10px;
        }

        i {
          @include absolute_center_both();
          text-align: center;
          margin-top: -1px;
        }
      }
    }

    &.offer-item-inner-home h5 {
      padding: 20px 60px 20px 20px;

      @include media-width(992px) {
        padding: 15px 50px 15px 10px;
      }
    }
  }
}

.products {
  .offer-item {
    @include media-width(992px) {
      padding: 5px;
    }
    
    .offer-item-inner {
      h5 {
        @include media-width(992px) {
          padding: 15px 10px;
        }
      }
    }
  }
}

/*--------------------------------------
            PRODUCTS
--------------------------------------*/
.products-nav {
  .home-page-content & {
    padding-top: 62px;
  }

  padding-right: 15px;

  @include media-width(768px) {
    padding-right: 0;
  }
}

.products-nav-inner {
  padding: 20px 25px;
  border-radius: $border-radius-base;
  background-color: $black;

  .products-nav-header {
    color: $theme-color;
  }

  @include media-width(768px) {
    max-width: 300px;
    width: 100%;
    padding: 12px 10px;
    margin: 0 auto 20px;
  }

  h5 {
    margin: 0 0 20px;
    color: $white;

    @include media-width(768px) {
      position: relative;
      margin: 0;
      cursor: pointer;

      &:after {
        position: absolute;
        right: 0;
        font-family: 'FontAwesome';
        content: '\f0c9';
        font-size: 15px;
      }
    }
  }

  > ul {
    @include media-width(768px) {
      display: none;
      margin-top: 15px;
    }

    > li {
      margin-bottom: 7px;

      @include media-width(768px) {
        margin-bottom: 5px;
      }

      > a {
        display: block;
        padding-bottom: 7px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        text-transform: uppercase;

        @include media-width(768px) {
          padding-bottom: 5px;
        }
      }

      &.current {
        > a {
          font-weight: 900;
        }
      }
    }

    li {
      a {
        color: $white;
        text-decoration: none;
        transition: $transition;
      }

      &:hover,
      &.current {
        > a {
          color: $theme-color;
        }
      }

      ul {
        @include list_caret();
        padding: 10px 0;

        @include media-width(768px) {
          padding: 5px 0;
        }

        li {
          margin-bottom: 2px;
        }
      }
    }
  }
}

.products > .section-header {
  @include media-width(768px) {
    display: none;
  }

  .section-header-tag {
    text-transform: uppercase;
  }
}

/*--------------------------------------
              PRODUCT
--------------------------------------*/
.product-description {
  padding: 10px 0;
}

.product-price-container {
  padding: 15px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  @include media-width(992px) {
    padding: 9px 0;
  }

  @include media-width(768px) {
    text-align: center;

    .text-right {
      text-align: center;
    }
  }

  .price {
    margin-top: 12px;
    font-size: 24px;
    color: $theme-color;

    @include media-width(992px) {
      margin-top: 8px;
      margin-bottom: 0;
      font-size: 22px;
    }

    @include media-width(768px) {
      margin: 0;
      margin-bottom: 10px;
      font-size: 18px;
    }
  }
}

.product-single {
  @include media-width(992px) {
    .col-md-7.pull-right {
      float: none !important;
    }
  }
}

/*--------------------------------------
          PRODUCT GALLERY
--------------------------------------*/
.product-gallery {
  padding-right: 30px;

  @include media-width(992px) {
    padding-right: 0;
  }
  
  img {
    margin: auto;
  }
}

.product-gallery-main {
  .pgm-item {
    display: block;
    max-width: 340px;
    width: 100%;
    outline: none !important;

    @include media-width(992px) {
      max-width: 280px;
      margin: auto;

      img {
        max-height: 300px;
      }
    }
  }
}

.product-gallery-thumbnail {
  width: 90px;
  height: 90px;
  padding: 3px;
  border: solid 1px #e1e1e1;
  border-radius: $border-radius-base;
  transition: $transition;
  cursor: pointer;
  margin: auto;

  @include media-width(992px) {
    width: 80px;
    height: 80px;
    width: 100%;
  }

  &:hover,
  &.current {
    border-color: $theme-color;
  }
}

.product-gallery-thumbnails {
  @include media-width(992px) {
    max-width: 280px;
    margin: auto;
  }

  .owl-dots {
    margin-top: 10px;
    text-align: center;

    .owl-dot {
      width: 13px;
      height: 13px;
      margin: 0 4px;
      display: inline-block;
      background-color: $black;
      border-radius: 50%;

      &.active {
        background-color: $theme-green;
      }
    }
  }
}

.product-gallery-static {
  text-align: center;
  max-width: 100%;

  .product-gallery-item {
    padding: 10px;

    @include media-width(992px) {
      width: 16.6666%;
      padding: 5px;
    }

    @include media-width(640px) {
      width: 20%;
    }

    @include media-width(480px) {
      width: 25%;
    }

    @include media-width(400px) {
      width: 33.3333%;
    }
    
    @include media-width(320px) {
      width: 50%;
    }
  }

  .product-gallery-thumbnail {
    display: block;
    margin: auto;
    outline: none !important;
  }

  img {
    display: inline-block;
  }
}

.product-order-form {
  margin-bottom: 30px;
}

/*--------------------------------------
            SEARCH FORM
--------------------------------------*/
.search-form {
  position: relative;

  .form-control {
    padding-right: 40px;
  }

  button {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 38px;
    height: 38px;
    color: $theme-color;
    background-color: $white;
    border: none;
    outline: none;
  }
}

/*--------------------------------------
            GOOGLE MAP
--------------------------------------*/
.google-map {
  width: 100%;
  height: 550px;

  @include media-width(1152px) {
    height: 450px;
  }
}

/*--------------------------------------
              CONTACT PAGE
--------------------------------------*/
.contact-details-container {
  @include media-width(1152px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include media-width(768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.contact-details {
  position: relative;
  z-index: 2;
  border-radius: $border-radius-base;
  background-color: $theme-green;
  color: $white;

  a {
    color: $white;
  }

  .c-col {
    padding: 25px;

    @include media-width(1152px) {
      padding: 15px;
    }

    @include media-width(768px) {
      .row {
        max-width: 220px;
        margin: auto;
      }

      .c-icon {
        width: 35px;
      }
    }

    .fa {
      font-size: 60px;
      color: $black;

      @include media-width(1152px) {
        font-size: 48px;
      }

      @include media-width(768px) {
        font-size: 32px;
      }
    }

    .c-content {
      overflow: hidden;
      padding-left: 20px;

      @include media-width(768px) {
        padding-left: 10px;
      }

      .c-address-text,
      .c-phone,
      .c-email {
        margin: 0;
      }
    }

    .c-header {
      margin-bottom: 5px;
      font-size: $font-size-large;

      @include media-width(1152px) {
        font-size: $font-size-medium-large;
      }
    }
  }
}

.contact-form {
  border-radius: $border-radius-base;
  background-color: $black;
  padding-bottom: 6px;

  .c-form-header {
    font-size: $font-size-large;
    margin-bottom: 20px;
    color: $white;

    strong {
      display: block;
      padding: 20px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  .form-group {
    padding: 0 15px;

    @include media-width(768px) {
      padding: 0 5px;
    }
  }
}

.wpcf7-form {
  span.wpcf7-not-valid-tip {
    margin-bottom: 0;
    padding: 2px;
    font-size: $font-size-small;
    display: none;
  }

  .wpcf7-not-valid {
    border: 2px solid #C00 !important;
  }

  div.wpcf7-response-output {
    max-width: 80%;
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    padding: 0;
  }

  div.wpcf7-validation-errors,
  div.wpcf7-mail-sent-ng,
  .wpcf7-mail-sent-ok {
    padding: 5px !important;
    border: none;
    background-color: #c00;
    color: $white;
    text-align: center;
    font-size: $font-size-base;
  }

  .wpcf7-mail-sent-ok {
    background-color: #090;
  }

  span.wpcf7-list-item {
    display: inline-block;
    margin: 0 10px 0 0;
  }
}

/*--------------------------------------
                PAGE 404
--------------------------------------*/
.page-404 {
  padding: 40px 0;
}


/*--------------------------------------
                FOOTER
--------------------------------------*/
.footer {
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2);
  background-color: $white;
}

.footer-menu {
  padding: 0 15px 80px;

 
  .section-header.margin-top {
    @include media-width(992px) {
      margin-top: 20px;
    }

    @include media-width(768px) {
      margin-top: 10px;
    }
  }

  @include media-width(768px) {
    padding: 5px 0 0;
    text-align: center;
  }

  .footer-nav-header {
    margin-bottom: 20px;
    color: $theme-color;

    @include media-width(768px) {
      margin-bottom: 10px;
    }
  }

  .footer-nav {
    @include list_caret();

    li {
      margin-bottom: 2px;

      a {
        font-size: $font-size-small;
        color: $black;
      }

      @include media-width(768px) {
        &:before {
          display: none;
          margin-right: 0;
        }
      }
    }
  }

  &.two-cols {
    .footer-nav {
      li {
        width: 49%;
        display: inline-block;

        @include media-width(768px) {
          width: 100%;
          display: block;
        }
      }
    }
  }

  .caret,
  .dropdown-menu {
    display: none;
  }
}

.footer-bottom {

  @include media-width(768px) {
    padding: 10px;
    text-align: center;
  }

  > .row {
    padding: 20px 0;
    border-top: solid 1px rgba(0, 0, 0, 0.2);

    @include media-width(768px) {
      padding: 10px 0;
    }
  }

  .footer-logo,
  .copyrights {
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    font-size: $font-size-small;
    line-height: $line-height-base;

    @include media-width(768px) {
      width: 100%;
    }
  }

  .footer-logo {
    max-width: 110px;
    margin-right: 20px;

    @include media-width(768px) {
      margin-right: 0;
      margin-bottom: 10px;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

/*--------------------------------------
                  RWD
--------------------------------------*/  
.rwd-size {
  opacity: 0;

  @include media-width(1366px) {
    &#s1366 {
      opacity: 1;
    }
  }
  
  @include media-width(1280px) {
    &#s1280 {
      opacity: 1;
    }
  }
  
  @include media-width(1152px) {
    &#s1152 {
      opacity: 1;
    }
  }
  
  @include media-width(1024px) {
    &#s1024 {
      opacity: 1;
    }
  }
  
  @include media-width(992px) {
    &#s992 {
      opacity: 1;
    }
  }
  
  @include media-width(860px) {
    &#s860 {
      opacity: 1;
    }
  }
  
  @include media-width(768px) {
    &#s768 {
      opacity: 1;
    }
  }
  
  @include media-width(640px) {
    &#s640 {
      opacity: 1;
    }
  }
  
  @include media-width(540px) {
    &#s540 {
      opacity: 1;
    }
  }
  
  @include media-width(480px) {
    &#s480 {
      opacity: 1;
    }
  }
  
  @include media-width(360px) {
    &#s360 {
      opacity: 1;
    }
  }
  
  @include media-width(320px) {
    &#s320 {
      opacity: 1;
    }
  }
}